import { useState } from "react"
import { FAQData } from "../../constants"

const FAQSection = () => {
	const [clickedItem, setClickedItem] = useState("")
	return(
		<div className="container-xxl py-5">
			<div className="container">
				<div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px;"}}>
					<h1 className="display-6">FAQs</h1>
					<p className="text-primary fs-5 mb-5">Frequently Asked Questions</p>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-10">
						<div className="accordion" id="accordionExample">
							{
								FAQData.map((data) => (
									<div key={data.key} className="accordion-item wow fadeInUp" data-wow-delay={data.delay}>
										<h2 className="accordion-header" id={data.id}>
											<button className={`accordion-button ${data.targetId === clickedItem ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse"
												data-bs-target={`#${data.targetId}`} aria-expanded={data.targetId === clickedItem ? true : false} aria-controls={data.targetId} onClick={() => { 
													if(clickedItem === data.targetId) {
														setClickedItem("")
													} else {
														setClickedItem(data.targetId)
													}
												}}>
											{data.question}
											</button>
										</h2>
										<div id={data.targetId} className={`accordion-collapse collapse ${data.targetId === clickedItem ? "show" : ""}`} aria-labelledby={data.id}
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												{data.answer}
											</div>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FAQSection
