import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';
import { getDetailFromId } from "../../helper";
import { productsData } from "../../constants";
import PageHeader from "../../components/PageHeader/PageHeader";

const ProductsDetailPage = (props) => {
	const { id } = useParams();
	const singleProductDetail = getDetailFromId(productsData, id, "id");
	const {title, image, description} = singleProductDetail
	return(
		<Layout>
			<div className="single-product mt-5 mb-5">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="single-product-img">
								<img src={image} alt="" />
							</div>
						</div>
						<div className="col-md-7">
							<div className="single-product-content">
								<h3>{title}</h3>
								<p>{description}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default ProductsDetailPage;