import Menu from "../Menu/Menu";
import TopBar from "../TopBar/TopBar";


const Header = () => {
    return(
        <>
            {/* <TopBar /> */}
            <Menu />
        </>
    )
}

export default Header;