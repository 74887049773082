import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export const verifyToken = createAsyncThunk(
  'contact/verifyCaptcha',
  async (token, thunkAPI) => {
    try {
      const response = await axios
      .post('https://demo.dexterousdynamics.com/api/api/checkCaptcha', 
        { "code": token }
      );
      console.log(response)
      if(response.status === 200 )
        return true
      else return false
      
    } catch (err) {
      console.log(err)
    }
  },
)

const contactSlice = createSlice({
  name: 'contact',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyToken.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle'
          state.entities.push(action.payload)
          state.currentRequestId = undefined
        }
      })
      .addCase(verifyToken.rejected, (state, action) => {
        const { requestId } = action.meta
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle'
          state.error = action.error
          state.currentRequestId = undefined
        }
      })
  },
})

export default contactSlice.reducer