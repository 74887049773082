
import AboutImage from "../../assets/images/who-we-are.gif"


const FeaturesSection = () => {
	return(
		<div className="container-xxl py-5">
		<div className="container">
			<div className="row g-5 align-items-center">
				<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
					<p className="fs-5 fw-bold text-primary">Why Choose Us!</p>
					<h1 className="display-5 mb-4">Few Reasons Why People Are Choosing Us!</h1>
					<p className="mb-4">Our cutting-edge technology, tailored services, and expert team ensure that we meet and exceed our clients' needs. With Dexterous Dynamics, you can trust in a partner who prioritizes quality, efficiency, and sustainability.</p>
					<a className="btn btn-primary py-3 px-4" href="/">Explore More</a>
				</div>
				<div className="col-lg-6">
					<div className="row g-4 align-items-center justify-content-center">
						<div className="width-75">
							<img alt="Why choose us" className="img-fluid rounded" data-wow-delay="0.1s" src={AboutImage} />	
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default FeaturesSection