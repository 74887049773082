import {useState, useEffect} from "react"
import { FaArrowRight, FaAngleDown } from "react-icons/fa6";
import Logo from "../../assets/images/logo.png"

const Menu = () => {
    const [scrolltopdata, setscrolltopdata] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setscrolltopdata(true);
            } else {
                setscrolltopdata(false);
            }
        });
    }, [])
    return(
        <nav className={`${scrolltopdata ? "shadow-sm" : ""} navbar navbar-expand-lg bg-white navbar-light sticky-top p-0`} style={scrolltopdata ? {top: "0px" } : {top: "-100px"}} > 
            <a href="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                <img className="logo" alt="Logo" src={Logo} />
            </a>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <a href={`/`} className="nav-item nav-link">Home</a>
                    <a href={`/about`} className="nav-item nav-link">About</a>
                    <div className="nav-item dropdown">
                        <a href="/products" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products <FaAngleDown /></a>
                        <div className="dropdown-menu bg-light m-0">
                            <a href="/products/water-quality-monitoring-systems" className="dropdown-item">Water Quality Monitoring Systems</a>
                            <a href="/products/etp-stp-automation" className="dropdown-item">ETP/STP Automation (SCADA)</a>
                            <a href="/products/coastal-water-monitoring-systems" className="dropdown-item">Coastal Water Monitoring Systems</a>
                            <a href="/products/aquaculture" className="dropdown-item">Aquaculture</a>
                            <a href="/products/fleet-monitoring-systems-for-fishing-boats" className="dropdown-item">Fleet Monitoring Systems for Fishing Boats</a>
                            <a href="/products/smart-city-pollution-monitoring-systems" className="dropdown-item">Smart City Pollution Monitoring Systems</a>
                        </div>
                    </div>
                    {/* <a href={`/projects`} className="nav-item nav-link">Projects</a> */}
                    {/* <a href={`/resources`} className="nav-item nav-link">Resources</a> */}
                    <a href={`/faq`} className="nav-item nav-link">FAQ</a>
                    <a href={`/blog`} className="nav-item nav-link">Blog</a>
                    {/* <a href={`/career`} className="nav-item nav-link">Career</a> */}
                    <a href={`/contact`} className="nav-item nav-link">Contact</a>
                </div>
                <a href="/contact" className="btn btn-primary py-4 px-lg-4 rounded-0 d-none d-lg-block">Get In Touch<FaArrowRight className="ms-3" /></a>
            </div>
        </nav>
    )
}

export default Menu;