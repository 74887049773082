import Slider from "react-slick";
import { sliderSettings, sliderData } from "../../constants";

const HomeSlider = () => {
	
	return(
		<div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
			<Slider {...sliderSettings}>
				{
					sliderData.map((data) => {
						return(
							<div key={data.key} class="carousel-item">
								<img class="w-100" src={data.image} alt={data.headingText} />
								<div class="carousel-caption">
									<div class="container position-absolute top-25">
										<div class="row justify-content-center">
											<div class={data.textClass}>
												<h1 class="display-4 text-white mb-5 animated slideInDown">{data.headingText}</h1>
												<a href={data.buttonLink} class="btn btn-primary py-sm-3 px-sm-4">{data.buttonName}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					})
				}
			</Slider>
		</div>
	)
}

export default HomeSlider;