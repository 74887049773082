import { ParallaxBanner, ParallaxBannerLayer  } from 'react-scroll-parallax';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { factsData } from "../../constants"
import slider1 from "../../assets/images/slider2.jpg"

const FactsSection = () => {
	return(
		<ParallaxBanner className="facts-parallax">
	  <ParallaxBannerLayer image={slider1} speed={-30} />
			<ParallaxBannerLayer className="parallax-content">
					<div className="container py-5">
						<div className="row g-5">
							{
								factsData.map((data) => (
									<div key={data.key} className="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay={data.delay}>
										<h1 className="display-4 text-white" data-toggle="counter-up">
										<CountUp start={0} end={data.count} redraw={true}>
											{({ countUpRef, start }) => (
													<VisibilitySensor onChange={start} delayedCall>
															<span ref={countUpRef} />
													</VisibilitySensor>
											)}
										</CountUp>
										</h1>
										<span className="fs-5 fw-semi-bold text-light">{data.title}</span>
									</div>
								))
							}
						</div>
					</div>
			</ParallaxBannerLayer>
		</ParallaxBanner>
	)
}

export default FactsSection