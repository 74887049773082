import AboutSection from "../../components/AboutSection/AboutSection";
import FactsSection from "../../components/FactsSection/FactsSection";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import ProductsSection from "../../components/ProductsSection/ProductsSection";

const AboutPage = () => {
    return(
        <Layout>
            <PageHeader header="About Us" />
            <AboutSection />
            <FactsSection />
            <FeaturesSection />
            <ProductsSection />
        </Layout>
    )
}

export default AboutPage;