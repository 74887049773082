import Layout from "../../components/Layout/Layout";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import PageHeader from "../../components/PageHeader/PageHeader";

const ProductsPage = () => {
    return(
        <Layout>
            <PageHeader header="Products" />
            <ProductsSection />
        </Layout>
    )
}

export default ProductsPage;