import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ParallaxBanner, ParallaxBannerLayer  } from 'react-scroll-parallax';
import ReCAPTCHA from "react-google-recaptcha";
import slider1 from "../../assets/images/slider1.jpg"
import { useDispatch } from 'react-redux'
import { verifyToken } from '../../redux/contactSlice';


const ContactSection = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [stateMessage, setStateMessage] = useState(null);
	const captchaRef = useRef(null)	
	const dispatch = useDispatch()

	const onChange = (e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
		console.log(token)
		captchaRef.current.reset();
	}

	const verifyCaptcha = async (token) => {
    try {
      const status = await dispatch(verifyToken(token)).unwrap()
      console.log('success', `Fetched ${status}`)
    } catch (err) {
      console.log('error', `Fetch failed: ${err.message}`)
    }
  }

	const sendEmail = (e) => {
		e.persist();
		e.preventDefault();
		setIsSubmitting(true);
		const token = captchaRef.current.getValue();
		const result = verifyCaptcha(token)
		console.log(result)
    if (!token || !result) {
      alert('Please verify the reCAPTCHA!')
    } else {
      console.log(token)
			console.log(result)
			emailjs
				.sendForm(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				e.target,
				process.env.REACT_APP_PUBLIC_KEY
				)
				.then(
				(result) => {
					setStateMessage('Message sent!');
					setIsSubmitting(false);
					setTimeout(() => {
					setStateMessage(null);
					}, 5000); // hide message after 5 seconds
				},
				(error) => {
					setStateMessage('Something went wrong, please try again later');
					setIsSubmitting(false);
					setTimeout(() => {
					setStateMessage(null);
					}, 5000); // hide message after 5 seconds
				}
		  );
			// Clears the form after sending the email
			e.target.reset();
			captchaRef.current.reset();
    }
		
	};
	return(
		<ParallaxBanner className="contact-parallax">
			<ParallaxBannerLayer image={slider1} speed={-30} />
			<ParallaxBannerLayer  className="parallax-content">
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-7">
							<div className="bg-white rounded p-4 p-sm-5 wow fadeIn" data-wow-delay="0.5s">
								<h1 className="display-5 text-center mb-5">Get In Touch</h1>
								<form className="row g-3" onSubmit={sendEmail}>
									<div className="col-sm-6">
										<div className="form-floating">
											<input name="from_name" type="text" className="form-control bg-light border-0" id="gname" placeholder="Your Name" required />
											<label for="gname">Name*</label>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-floating">
											<input name='from_email' type="email" className="form-control bg-light border-0" id="gmail" placeholder="Your Email" required />
											<label for="gmail">Email*</label>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-floating">
											<input name="from_mobile" type="text" className="form-control bg-light border-0" id="cname" placeholder="Your Mobile" required />
											<label for="cname">Mobile*</label>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-floating">
											<input name="subject" type="text" className="form-control bg-light border-0" id="cage" placeholder="Subject" />
											<label for="cage">Subject*</label>
										</div>
									</div>
									<div className="col-12">
										<div className="form-floating">
											<textarea name="message" className="form-control bg-light border-0" placeholder="Leave a message here" id="message" style={{height: "100px"}}></textarea>
											<label for="message">Message*</label>
										</div>
									</div>
									<ReCAPTCHA
										sitekey={process.env.REACT_APP_CAPTCHA_KEY}
										ref={captchaRef}
										onChange={onChange}
									/>
									<div className="col-12 text-center">
										<button className="btn btn-primary py-3 px-4" type="submit" disabled={isSubmitting}>Send</button>
										{stateMessage && <p>{stateMessage}</p>}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</ParallaxBannerLayer>
		</ParallaxBanner>
	)
}

export default ContactSection