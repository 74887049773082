import FAQSection from "../../components/FAQSection/FAQSection";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";

const FAQPage = () => {
    return(
        <Layout>
            <PageHeader header="FAQ" />
            <FAQSection />
        </Layout>
    )
}

export default FAQPage;