import { useEffect } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout = ({ children }) => {

  useEffect(() => {
    //Set Page Title.
    document.title = "Dexterous Dynamics"
  
  }, [])

  return (
    <>
      <Header/>
      <main>{children}</main>
      <Footer />
    </>
  )}

export default Layout