import { useEffect } from 'react';
import './App.scss';
import HomeSlider from './components/HomeSlider/HomeSlider';
import Layout from './components/Layout/Layout';
import {WOW} from 'wowjs';
import TopFeature from './components/TopFeature/TopFeature';
import AboutSection from './components/AboutSection/AboutSection';
import FactsSection from './components/FactsSection/FactsSection';
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import ProductsSection from './components/ProductsSection/ProductsSection';
import ContactSection from './components/ContactSection/ContactSection';
import TeamSection from './components/TeamSection/TeamSection';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
//import TestimonialSection from './components/TestimonialSection/TestimonialSection';

const App = () => {
	
	useEffect(() => {

		const wow = new WOW({
			offset: 100,
			mobile: false, 
			live: true 
		})
		wow.init();

	}, [])

  return (
		<div className="App">
			<ErrorBoundary>
				<Layout> 
					<HomeSlider />
					<TopFeature />
					<AboutSection />
					<FactsSection />
					<FeaturesSection />
					<ProductsSection />
					<ContactSection />
					<TeamSection />
					{/* <TestimonialSection /> */}
				</Layout>
			</ErrorBoundary>
		</div>
  );
}

export default App;
