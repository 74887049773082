import ContactSection from "../../components/ContactSection/ContactSection";
import Layout from "../../components/Layout/Layout";

const ContactPage = () => {
    return(
        <Layout>
            <ContactSection />
        </Layout>
    )
}

export default ContactPage;