import { teamData } from "../../constants";


const TeamSection = () => {
	return (
		<div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                <p className="fs-5 fw-bold text-primary">Our Team</p>
                <h1 className="display-5 mb-5">Dedicated & Experienced Team Members</h1>
            </div>
            <div className="row g-4">
							{
								teamData.map((data) => {
									return(
										<div key={data.key} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={data.delay}>
											<div className="team-item rounded">
												<img className="img-fluid" src={data.image} alt={data.memberName} />
												<div className="team-text">
													<h4 className="mb-0">{data.memberName}</h4>
													<p className="text-primary">{data.designation}</p>
												</div>
											</div>
										</div>
									)
								})
							}
            </div>
        </div>
    </div>
	)
}

export default TeamSection;