import Layout from "../Layout/Layout"
import Image404 from "../../assets/images/404.gif"

const PageNotFound = () => {
  return(
    <Layout>
      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container text-center">
              <div class="row justify-content-center">
                  <div class="col-lg-6">
                      <img className="image-404" src={Image404} alt="404 error" />
                      <h1 class="mb-4">Page Not Found</h1>
                      <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website!</p>
                      <a class="btn btn-primary py-3 px-4" href="/">Go Back To Home</a>
                  </div>
              </div>
          </div>
      </div>
    </Layout>
  )

}

export default PageNotFound