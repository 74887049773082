import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import SingleBlogCard from "../../components/SingleBlogCard/SingleBlogCard";
import { blogDetails } from "../../constants";

const Blog = () => {
	return(
		<Layout>
			<PageHeader header="Blog" />
			<div class="container-xxl py-5 latest-blog mt-150 mb-150">
				<div class="container">
					<div class="row">
						{
							blogDetails.map((data) => {
								return(
									<div key={data.key} class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={data.delay}>
										<SingleBlogCard {...data} />
									</div>
								)
							})
						}
					</div>

					{/* <div class="row">
						<div class="container">
							<div class="row">
								<div class="col-lg-12 text-center">
									<div class="pagination-wrap">
										<ul>
											<li><a href="#">Prev</a></li>
											<li><a href="#">1</a></li>
											<li><a class="active" href="#">2</a></li>
											<li><a href="#">3</a></li>
											<li><a href="#">Next</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</Layout>
	)
}

export default Blog;