import { FaEye, FaLink } from "react-icons/fa6";
import Slider1 from "../../assets/images/slider1.jpg";
import { projectCategories, projectsData } from "../../constants";

const ProjectsSection = () => {
	return(
		<div className="container-xxl py-5">
				<div className="container">
						<div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxHeight: "500px"}}>
								<p className="fs-5 fw-bold text-primary">Our Projects</p>
								<h1 className="display-5 mb-5">Some Of Our Wonderful Projects</h1>
						</div>
						<div className="row wow fadeInUp" data-wow-delay="0.3s">
								<div className="col-12 text-center">
										<ul className="list-inline rounded mb-5" id="portfolio-flters">
											{
												projectCategories.map((data) => (
													<li key={data.id} className={`mx-2 ${data.isActive ? "active" : ""}`} data-filter="*">{data.title}</li>
												))
											}
										</ul>
								</div>
						</div>
						<div className="row g-4 portfolio-container">
							{
								projectsData.map((data) => {
									return(
									<div key={data.key} className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay={data.delay}>
										<div className="portfolio-inner rounded">
											<img className="img-fluid" src={data.image} alt="" />
											<div className="portfolio-text">
												<h4 className="text-white mb-4">{data.title}</h4>
												<div className="d-flex">
														<a className="btn btn-lg-square rounded-circle mx-2" href={data.viewButtonLink} data-lightbox="portfolio"><FaEye /> </a>
														<a className="btn btn-lg-square rounded-circle mx-2" href={data.buttonLink}><FaLink /></a>
												</div>
											</div>
										</div>
									</div>
									)
								})
							}	
						</div>
				</div>
		</div>
	)
}

export default ProjectsSection;