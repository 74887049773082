import AboutImage from "../../assets/images/about-gif.gif"
import { aboutSectionData } from "../../constants"

const AboutSection = () => {
	return(
		<div className="container-xxl py-5">
			<div className="container">
				<div className="row g-5 align-items-end">
					<div className="col-lg-4 col-md-5 wow fadeInUp text-center" data-wow-delay="0.1s">
						<h1 className="display-1 text-primary mb-0">8</h1>
						<h4 className="mb-4">Years of Experience</h4>
						<img alt="About" className="img-fluid rounded" data-wow-delay="0.1s" src={AboutImage} />
					</div>
					<div className="col-lg-5 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
						{/*  */}
						<h1 className="display-5 mb-4">We bring electronics to life</h1>
						<p className="mb-4">Established with the vision to innovate automation, Dexterous Dynamics helps develop automatic machines for various industries. Innovating the way our industries function, to make it a faster, more accurate, and safer environment to work in, has always been our goal. We deliver automation for your specific needs based on your industry type and product.
						<br /><br />
						We also specialize in IoT-based systems to integrate into existing products to make them user-friendly and state-of-the-art. 
						<br /><br />
						We are Dexterous Dynamics, a team of passionate engineers and environmental specialists dedicated to empowering industries with customized automation solutions for cleaner operations. Real-time data, coupled with intelligent automation, holds the key to minimizing environmental impact while optimizing industrial processes.
						</p>
						<a className="btn btn-primary py-3 px-4" href="/">Explore More</a>
					</div>
					<div className="col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
						<div className="row g-5">
							{
								aboutSectionData.map((data) => {
									return(
										<div key={data.key} className="col-12 col-sm-6 col-lg-12">
											<div className="border-start ps-4">
												{data.icon}
												<h4 className="mb-3">{data.title}</h4>
												<span>{data.description}</span>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
	
}

export default AboutSection