import { topFeaturesData } from "../../constants";

const TopFeature = () => {
	return(
		<div className="container-fluid top-feature py-5 pt-lg-0">
			<div className="container py-5 pt-lg-0">
				<div className="row gx-0">
					{
						topFeaturesData.map((data) => {
							return(
								<div key={data.key} className="col-lg-4 wow fadeIn" data-wow-delay={data.delay}>
									<div className="bg-white shadow d-flex align-items-center h-100 px-5 top-feature-container">
										<div className="d-flex">
											<div className="flex-shrink-0 btn-lg-square rounded-circle bg-light">
												{data.icon}
											</div>
											<div className="ps-3">
												<h4>{data.title}</h4>
												<span>{data.description}</span>
											</div>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default TopFeature;